<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px" class="demo-rulesFrom"
      style="height: 100%">
      <el-dialog v-dialogDrag :title="title" :visible.sync="dialogVisible" :before-close="handleClose"
        :close-on-press-escape="false" :close-on-click-modal="false">
        <el-row>
          <!--<el-col :span="12">
					 <el-form-item type="type" label="创建时间" prop="createTime">

              <el-date-picker v-model.trim="ruleForm.createTime" class="input-tit datepicker" type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss" placeholder="创建时间">
              </el-date-picker>

						</el-form-item>
					</el-col> -->
          <el-col :span="8">
            <el-form-item type="type" label="省" prop="province">
              <el-select :disabled="disableds" v-model="ruleForm.province" placeholder="省" class="selectClass"
                @change="provinceChange">
                <el-option v-for="(item,i) in provincelist" :key="i" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item type="type" label="市" prop="city">
              <el-select :disabled="disableds" clearable v-model="ruleForm.city" placeholder="市" class="selectClass"
                @change="cityChange">
                <el-option v-for="(item,i) in citylist" :key="i" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item type="type" label="县" prop="county">
              <el-select :disabled="disableds" clearable v-model="ruleForm.county" placeholder="县" class="selectClass">
                <el-option v-for="(item,i) in countylist" :key="i" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item type="type" label="名称" prop="groupName">
              <el-input :disabled="disableds" v-model.trim="ruleForm.groupName" placeholder="名称" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item type="type" label="分类" prop="groupClassification">
              <el-select :disabled="disableds" v-model="ruleForm.groupClassification" placeholder="分类"
                class="selectClass">
                <el-option v-for="(item,i) in groupClassificationlist" :key="i" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>


          <el-col :span="8">
            <el-form-item type="type" label="一级工种" prop="workerTypeOne">
              <el-input :disabled="disableds" v-model.trim="ruleForm.workerTypeOne" placeholder="一级工种" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item type="type" label="二级工种" prop="workerTypeTwo">
              <el-input :disabled="disableds" v-model.trim="ruleForm.workerTypeTwo" placeholder="二级工种" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item type="type" label="群类型" prop="groupType">
              <el-input :disabled="disableds" v-model.trim="ruleForm.groupType" placeholder="群类型" />

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item type="type" label="群链接" prop="link">
              <el-input :disabled="disableds" v-model.trim="ruleForm.link" placeholder="群链接" />

            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item type="type" label="简介" prop="remarks">
              <el-input :disabled="disableds" v-model.trim="ruleForm.remarks" type="textarea" :rows="3"
                placeholder="简介" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item type="type" label="群头像" prop="">
              <el-image class="HximgBg" v-if="dialogState=='edit'&&imgUrl.length==0" :src="imgUrlurl"
                :preview-src-list="[imgUrlurl]" :z-index="4000"></el-image><!-- accept=".jpg,.png"  -->
              <el-upload ref="Epupload" v-if="!disableds" action="" :on-preview="imgUrlPreview"
                :auto-upload="false" list-type="picture-card" :on-change="Epfilechange" :on-remove="EpleRemove">
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>

              </el-upload>

              <el-image v-if="imgDialogVisible" :src="dialogImageUrl" :preview-src-list="[dialogImageUrl]"
                :z-index="4000"></el-image>

            </el-form-item>
          </el-col>

        </el-row>
        <el-row>

          <el-col :span="24">
            <el-form-item type="type" label="群二维码" prop="">
              <el-image class="HximgBg" :src="TitleImg" v-if="dialogState=='edit' && TitlefileList.length==0"
                :preview-src-list="[TitleImg]" :z-index="4000"></el-image>
              <el-upload ref="Titleupload" accept=".jpg,.png," v-if="!disableds" action=""
                :on-preview="handlePictureCardPreview" :auto-upload="false" list-type="picture-card"
                :on-change="Titlefilechange" :on-remove="TitlehandleRemove">
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
              </el-upload>
              <el-image v-if="imgDialogVisible" :src="TitledialogImageUrl" :preview-src-list="[TitledialogImageUrl]"
                :z-index="4000"></el-image>

            </el-form-item>
          </el-col>


        </el-row>

        <span slot="footer" class="dialog-footer">
          <el-button v-if="!disableds" type="primary" @click="deteRmine('ruleForm')">确 定</el-button>
          <el-button @click="cancel">关 闭</el-button>
        </span>

        <transition name="el-fade-in-linear">
          <loading v-if="btnDisableds"></loading>
        </transition>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
  import {
    eworkgroupsave,
    eworkgroupupdate,
    uploadstatic
  } from "@/api/api";
  import loading from '@/components/Loading/loading.vue';
  import cityData from '@/common/js/city_code.json';

  export default {
    name: "",
    components: {
      loading
    },
    props: {
      dialogdata: Array,
      title: String,
    },
    data() {
      return {

        btnDisableds: false,
        dialogVisible: true,
        disableds: false,

        row: "",
        dialogState: "",
        ruleForm: {
          province: "", //省
          city: '', //市
          county: '', //县
          link:'',
          groupArea: "",
          groupClassification: "", //分类
          groupName: "",
          groupType: "",
          remarks: "",
          workerTypeOne: '',
          workerTypeTwo: "",

        },
        rules: {
          province: [{
            required: true,
            message: "请输入必填项",
            trigger: "change",
          }, ],
          
          groupClassification: [{
            required: true,
            message: "请输入必填项",
            trigger: "change",
          }, ],
          workerTypeOne: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          workerTypeTwo: [{
            required: false,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          
          link: [{
            required: false,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          groupName: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],

          groupType: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],
          imgUrlurl: [{
            required: true,
            message: "请上传群头像",
            trigger: "change",
          }, ],
          TitleImg: [{
            required: true,
            message: "请上传二维码",
            trigger: "change",
          }, ],
          remarks: [{
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          }, ],

        },
        provincelist: [],
        citylist: [],
        countylist: [],
        groupClassificationlist: [{
            name: "工人",
            id: 0
          },
          {
            name: "班组",
            id: 1
          },
          {
            name: "工程管理",
            id: 3
          },
        ], //分类列表
        dialogImageUrl: "",
        imgDialogVisible: false,
        imgUrl: [],
        imgUrlurl: '',
        hideUpload: false,
        TitleImg: '',
        TitlefileList: [],

      };
    },
    computed: {},
    watch: {},
    created() {},
    beforeDestroy() {},
    mounted() {
      this.provincelist = cityData;
      if (this.dialogdata.length == 2) {
        this.dialogState = this.dialogdata[1];
        this.row = this.dialogdata[0];
        console.log(this.row)

       this.ruleForm.groupArea = this.row.groupArea;
       this.ruleForm.groupClassification = Number(this.row.groupClassification);
       this.ruleForm.groupName = this.row.groupName;
       this.ruleForm.groupType = this.row.groupType;
       this.ruleForm.remarks = this.row.remarks;
       this.ruleForm.workerTypeOne = this.row.workerTypeOne;
       this.ruleForm.workerTypeTwo = this.row.workerTypeTwo;
       this.ruleForm.link = this.row.link;
       
       
      this.imgUrlurl = this.row.imgUrl;
      this.TitleImg = this.row.qrCode;



      }
    },
    methods: {
      handlePictureCardPreview(file) {
        this.TitledialogImageUrl = file.url;
        this.imgDialogVisible = true;
      },
      TitlehandleRemove(file, fileList) { //爵位标识
        this.hideUpload = false;
        this.TitlefileList = []
      },
      Titlefilechange(file, list) { //
        this.btnDisableds = true;
        if (file.raw.name.split('.')[file.raw.name.split('.').length - 1] == 'png' || file.raw.name.split('.')[file.raw
            .name.split('.').length - 1] == 'jpg') {
          this.TitlefileList = [file.raw]
          if (list.length > 1) {
            list.splice(0, 1);
          }

          let formData = new FormData();
          formData.append('file', this.TitlefileList[0])
          uploadstatic(formData).then((res) => {
            console.log(res)
            if (res.status == 200) {

              this.btnDisableds = false;
              this.TitleImg = res.data;
            } else {
              this.btnDisableds = false;

              this.$message.error(res.msg);
            }

          }).catch(() => {
            this.btnDisableds = false;

            this.$message.error('图片上传失败失败！');
          });



        } else {
          this.$message.error('上传图片只能是 png/jpg/gif 格式!');
          this.$refs.Titleupload.clearFiles()
          return;
        }
      },
      imgUrlPreview(file) {
        this.dialogImageUrl = file.url;
        this.imgDialogVisible = true;
      },
      EpleRemove(file, fileList) { // 删除文件
        this.imgUrl = []
      },
      Epfilechange(file, list) {
        if (file.raw.name.split('.')[file.raw.name.split('.').length - 1] == 'png' || file.raw.name.split('.')[file.raw
            .name.split('.').length - 1] == 'js') {
          this.imgUrl = [file.raw]

          if (list.length > 1) {
            list.splice(0, 1);
          }

          let formData = new FormData();
          formData.append('file', this.imgUrl[0])
          uploadstatic(formData).then((res) => {

            if (res.status == 200) {

              this.btnDisableds = false;
              this.imgUrlurl = res.data;
            } else {
              this.btnDisableds = false;

              this.$message.error(res.msg);
            }

          }).catch(() => {
            this.btnDisableds = false;

            this.$message.error('图片上传失败失败！');
          });





        } else {
          this.$message.error('上传头像图片只能是 png/jpg 格式!');
          this.$refs.Epupload.clearFiles()
          return;

        }
      },
      provinceChange(val) {
        this.ruleForm.city = ''
        this.ruleForm.county = ''

        for (let i = 0; i < this.provincelist.length; i++) {
          if (val == this.provincelist[i].name) {
            this.citylist = this.provincelist[i].city
          }
        }


      },
      cityChange(val) {
        this.ruleForm.county = '';

        for (let i = 0; i < this.citylist.length; i++) {
          if (val == this.citylist[i].name) {
            this.countylist = this.citylist[i].area
          }
        }
      },
      deteRmine(fromName) {

        this.$refs[fromName].validate((valid) => {
          if (valid) {
            if (this.ruleForm.province != '') {
              this.ruleForm.groupArea = this.ruleForm.province
            }
            if (this.ruleForm.city != '') {
              this.ruleForm.groupArea = this.ruleForm.city
            }
            if (this.ruleForm.county != '') {
              this.ruleForm.groupArea = this.ruleForm.county
            }
            if (this.dialogState != "edit") {

              let params = {
                groupArea: this.ruleForm.groupArea,
                groupClassification: this.ruleForm.groupClassification,
                groupName: this.ruleForm.groupName,
                groupType: this.ruleForm.groupType,
                remarks: this.ruleForm.remarks,
                workerTypeOne: this.ruleForm.workerTypeOne,
                workerTypeTwo: this.ruleForm.workerTypeTwo,
                link: this.ruleForm.link,
                
                
                qrCode:this.TitleImg, //二维码
                imgUrl:this.imgUrlurl,//头像
                
                
              };
              eworkgroupsave(params).then((res) => {
                if (res.status == 200) {
                  this.$message({
                    message: "添加成功！",
                    type: "success",
                  });
                  this.$emit("addetermine", {});
                } else {
                  this.$message.error(res.msg);
                }
              }).catch((error) => {
                this.$message.error("新增失败！");
              });
            } else {
              let params = {
                groupArea: this.ruleForm.groupArea,
                groupClassification: this.ruleForm.groupClassification,
                groupName: this.ruleForm.groupName,
                groupType: this.ruleForm.groupType,
                remarks: this.ruleForm.remarks,
                workerTypeOne: this.ruleForm.workerTypeOne,
                workerTypeTwo: this.ruleForm.workerTypeTwo,
                link: this.ruleForm.link,
                qrCode:this.TitleImg, //二维码
                imgUrl:this.imgUrlurl,//头像
                id: this.row.id,
              };
              eworkgroupupdate(params).then((res) => {
                if (res.status == 200) {
                  this.$message({
                    message: "编辑成功！",
                    type: "success",
                  });
                  this.$emit("addetermine", {});
                } else {
                  this.$message.error(res.msg);
                }
              }).catch((error) => {
                this.$message.error("编辑失败！");
              });
            }
          } else {
            return false;
          }
        });
      },
      cancel() {
        this.dialogVisible = false;
        this.$emit("getcancel");
      },

      handleClose() {
        this.dialogVisible = false;
        this.$emit("getcancel");
      },
    },
  };
</script>

<style scoped>
  .selectClass {
    width: 100%;
  }

  ::v-deep .el-dialog {
    width: 900px !important;
    border-radius: 8px;
    overflow: hidden;
  }

  ::v-deep .el-dialog__body {
    max-height: 500px;
    overflow: auto;
  }

  .rowTops {
    position: relative;
  }

  .rowTopa {
    position: absolute;
    left: 0px;
    top: 40px;
    right: 0px;
    margin: auto;
    max-height: 300px;
    background: #fff;
    border: 1px solid #e3e3e3;
    z-index: 100;
    border-radius: 5px;
    overflow: auto;
    padding-bottom: 10px;
  }

  .iptclose {
    position: absolute;
    right: 10px;
    top: 0px;
    color: #606266;
    font-size: 16px;
    line-height: 40px;
    z-index: 1000;
    transition: color 0.15s linear;
  }

  .iptclose:hover {
    color: #5cb6ff !important;
  }

  .HximgBg {
    width: 126px;
    height: 126px;
    float: left;
    margin-right: 10px;
    border-radius: 6px;
  }
</style>
