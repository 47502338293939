<template>
  <div class="container">
    <div class="query-title">
      <div class="demo-input-suffix">
        <p class="titname">报名记录</p>
        <el-button type="primary" class="tit-button" @click="addBtn">新 增</el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table highlight-current-row :header-cell-style="{ background: 'rgba(113,183,255,0.4)', color: '#606266', }"
        :data="tabledata" :height="tableHeight" border style="width: 100%">
        <el-table-column type="index" align='center' width="55" label="序号"></el-table-column>
        <el-table-column prop="createTime" label="报名时间" align="center"></el-table-column>
        <el-table-column prop="factoryName" label="报名工厂" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" align="center"></el-table-column>
        <el-table-column prop="link" label="性别" align="center"></el-table-column>
        <el-table-column prop="idCard" label="身份证号" align="center"></el-table-column>
        <el-table-column prop="phone" label="电话" align="center"></el-table-column>
        <el-table-column prop="state" label="状态" align="center">
          <template slot-scope="scope">
            <p>{{scope.row.state==0?'未处理':scope.row.state==1?'已处理':scope.row.state==2?'取消':'判断出错'}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="90" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.state==0" class="tabBnt" type="text" @click="edit(scope.row, scope.$index)">处理</el-button>
            <el-button v-if="scope.row.state==1" class="tabBnt" type="text" @click="canceledit(scope.row, scope.$index)">取消处理</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Dialog v-if="dialogVisible == true" :title="title" :dialogdata="dialogData" @getcancel="Getcancel"
      @addetermine="addeteRmine"></Dialog>
    <div class="fye">
      <pagintion :ToTal="total" :pageNums="pagenums" @dsydata="DsyData" @dstdata="DstData" />
    </div>
  </div>
</template>

<script>
  import Dialog from "./dialogPopup.vue";
  import pagintion from "@/components/pagintion/pagintion.vue";
  import {gsignupgetList,gsignupdelete,gsignupupdate} from "@/api/api";
  export default {
    name: "registrationrecord",
    components: {
      Dialog,
      pagintion,
    },
    props: {},
    data() {
      return {
        specimenname: "",
        tabledata: [],
        tableHeight: window.innerHeight - 235,
        dialogData: [],

        pageNum: 1,
        pageSize: 20,

        total: null,
        pagenums: null,
        dialogVisible: false,
        title: "",
        factoryName:'',
      };
    },
    computed: {},
    watch: {},
    created() {
      window.addEventListener('resize', this.getHeight)
    },
    destroyed() {
      window.removeEventListener('resize', this.getHeight)
    },
    beforeDestroy() {},
    mounted() {

      this.Datalist();
    },
    methods: {
      Backbtn() {
        this.$router.go(-1)
      },
      getHeight() {
        this.tableHeight = window.innerHeight - 235;
      },

      addBtn() {
        this.dialogData = [];
        this.dialogVisible = true;
        this.title = "新增";
      },
      Getcancel() {
        this.dialogVisible = false;
      },
      addeteRmine() {
        this.dialogVisible = false;
        this.Datalist();
      },
      DsyData(data) {
        this.pagenums = data.dsy;
        this.pageNum = data.dsy;
        this.Datalist()
      },
      DstData(data) {
        this.pageSize = data.dst;
        this.Datalist()
      },
      canceledit(row, index){
        this.$confirm("是否取消改处理?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = row
            params.state = 2;
            gsignupupdate(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "处理成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("处理失败！");
            });
          }
        }).catch(() => {});
      },
      edit(row, index) {
        /* this.dialogVisible = true;
        this.dialogData = [row, "edit"];
        this.title = "编辑"; */
        this.$confirm("是否处理该数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = row
            params.state = 1;
            gsignupupdate(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "处理成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("处理失败！");
            });
          }
        }).catch(() => {});



      },
      deletes(row) {
        this.$confirm("此操作将永久删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = [row.id]

            gsignupdelete(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("删除失败！");
            });
          }
        }).catch(() => {});

      },
      Datalist() {
        let params = {
          factoryName:'',//工厂名称
          idCard:'',//身份证
          name:"",//姓名
          phone:'',//手机号
          state:'',//状态 0未处理  1已处理 2取消
          size:this.pageSize,
          current:this.pageNum,
        }
        gsignupgetList(params).then((res) => {
          this.tabledata = res.data.list;
          //this.tabledata = res.data;
          this.total = Number(res.data.total);
        }).catch((error) => {
          this.$message.error("获取数据失败！");
        });
      },
    },
  };
</script>

<style scoped>

</style>
